@mixin two-column-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 15px;

  > * {
    flex: 0 1 calc(50% - 15px);
  }
}

@mixin column-flex {
  display: flex;
  flex-direction: column;
  gap: 5px;

  > * {
    // margin-bottom: 10px;
  }
}

@mixin side-menu {
  .mat-menu-trigger {
    position: absolute;
    top: 0;
    right: 0;
  }
}
