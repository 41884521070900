@use '@angular/material' as mat;

@import 'theme';

@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */

@include mat.all-component-themes($my-light-theme);

.dark-mode {
  @include mat.all-component-colors($my-dark-theme);
}
