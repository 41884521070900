@import "../node_modules/alertifyjs/build/css/alertify.css";
@import "../node_modules/alertifyjs/build/css/themes/bootstrap.min.css";
@import "../node_modules/alertifyjs/build/css/themes/default.min.css";

/* You can add global styles to this file, and also import other style files */
@import 'mixins.scss';

html, body { height: 100%; }
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;

  app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

* { box-sizing: border-box; }

mat-dialog-container.mat-dialog-container {
  padding: 0;
  margin: 0;

  mat-toolbar {
    // padding: 15px;
  }

  mat-dialog-content {
    padding: 1em;
    margin: 0;
    max-height: 60vh;
  }
}

.mat-tab-body-content {
  padding: 10px;
}

.mat-card {
  margin-bottom: 15px;
}

.mat-card-header-text {
  margin: 0 !important;
}

.w-50 {
  flex: 0 1 50px;
}

.w-75{
  flex: 0 1 75px;
}

.w-100{
  flex: 0 1 100px;
}

.w-125 {
  flex: 0 1 125px;
}

.w-150 {
  flex: 0 1 150px;
}

.two-column-cards {
  @include two-column-grid();
}

.card-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

mat-card-header.with-buttons {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;

  div.mat-card-header-text {
    flex: 1 1 100%;
  }

  div.buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}

.show-header-dashboard-row {
  display: flex;
  flex-direction: row;
  gap: 0 10px;

  .dashboard-box {
    flex: 0 0 calc(100%/3 - (10px));
  }
}

.button-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

mat-table.mobile-table {
  .mobile-label {
    width: 80px;
    font-weight: bold;
    color: var(--primary-color);
  }

  .mat-header-row {
    display: none;
  }

  .mat-row {
    flex-direction: column;
    align-items: start;
    padding: 8px 24px;
  }

  .mat-cell {
    flex: 1 1 auto;

  }

  mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
    padding-left: 0 !important;
  }

  mat-row::after, mat-header-row::after, mat-footer-row::after {
    min-height: 0 !important;
  }

}
.codex-editor {
  background-color: #f9f9f9;
}

mat-form-field.ng-dirty {
  //background-color: rgba(255, 165, 0, 0.45);
  div.mat-form-field-underline {
    background-color: rgba(255, 165, 0, 0.45);
  }
}

