@use '@angular/material' as mat;

$my-primary-pal: (
  50 : #e5ebee,
  100 : #bdcdd4,
  200 : #91acb7,
  300 : #658a9a,
  400 : #447185,
  500 : #23586f,
  600 : #1f5067,
  700 : #1a475c,
  800 : #153d52,
  900 : #0c2d40,
  A100 : #79c8ff,
  A200 : #46b3ff,
  A400 : #139eff,
  A700 : #0093f8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$my-accent-pal: (
  50 : #e9f1f9,
  100 : #c7ddef,
  200 : #a2c6e5,
  300 : #7cafdb,
  400 : #609dd3,
  500 : #448ccb,
  600 : #3e84c6,
  700 : #3579be,
  800 : #2d6fb8,
  900 : #1f5cac,
  A100 : #e5efff,
  A200 : #b2d1ff,
  A400 : #7fb3ff,
  A700 : #65a4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$terravesta-typography: mat.define-typography-config(
  $font-family: 'Roboto',
  $input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.2px)
);

$my-primary: mat.define-palette($my-primary-pal);
$my-accent: mat.define-palette($my-accent-pal);

$my-light-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: $terravesta-typography,
));

$my-dark-theme: mat.define-dark-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: $terravesta-typography,
));


